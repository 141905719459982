import { default as dashboardjzkybJk07pMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/admin/dashboard.vue?macro=true";
import { default as loginn54G01eWr6Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/admin/login.vue?macro=true";
import { default as choose_45category9tqMOMBTM2Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/choose-category.vue?macro=true";
import { default as default6soWIoS3r6Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/default.vue?macro=true";
import { default as indexe919MBM0p0Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/index.vue?macro=true";
import { default as check_45prizeK3Pv0Fa3E3Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/check-prize.vue?macro=true";
import { default as claim_45prizeL4QGYSdlJWMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/claim-prize.vue?macro=true";
import { default as indexwOD3wV8aH2Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/index.vue?macro=true";
import { default as max_45playedxtFKgbwNKzMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/max-played.vue?macro=true";
import { default as no_45winbbAACxlRg1Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimedxKLvYFDGHsMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/prize-claimed.vue?macro=true";
import { default as winKDBTasO00bMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/win.vue?macro=true";
import { default as wrong_45answerDvLs40TJcBMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correctdRCood4IVjMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/answer-correct.vue?macro=true";
import { default as beSH9ILRETp9Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/be.vue?macro=true";
import { default as congratulationsQaZ8iWIjxVMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/congratulations.vue?macro=true";
import { default as formYVf3bj5Dj2Meta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/form.vue?macro=true";
import { default as loginxSE5TcLQyfMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/login.vue?macro=true";
import { default as question1an7fVjljlMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/question.vue?macro=true";
import { default as thanksqy0TmbtEWbMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/thanks.vue?macro=true";
import { default as wheelltkFT2T4pkMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard___nl",
    path: "/admin/dashboard",
    meta: dashboardjzkybJk07pMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/admin/dashboard.vue")
  },
  {
    name: "admin-login___nl",
    path: "/admin/login",
    meta: loginn54G01eWr6Meta || {},
    alias: ["/login"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/admin/login.vue")
  },
  {
    name: "choose-category___nl",
    path: "/choose-category",
    meta: choose_45category9tqMOMBTM2Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/choose-category.vue")
  },
  {
    name: "default___nl",
    path: "/default",
    meta: default6soWIoS3r6Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/default.vue")
  },
  {
    name: "index___nl",
    path: "/",
    meta: indexe919MBM0p0Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/index.vue")
  },
  {
    name: "quiz-check-prize___nl",
    path: "/quiz/check-prize",
    meta: check_45prizeK3Pv0Fa3E3Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/check-prize.vue")
  },
  {
    name: "quiz-claim-prize___nl",
    path: "/quiz/claim-prize",
    meta: claim_45prizeL4QGYSdlJWMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz___nl",
    path: "/quiz",
    meta: indexwOD3wV8aH2Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played___nl",
    path: "/quiz/max-played",
    meta: max_45playedxtFKgbwNKzMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win___nl",
    path: "/quiz/no-win",
    meta: no_45winbbAACxlRg1Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed___nl",
    path: "/quiz/prize-claimed",
    meta: prize_45claimedxKLvYFDGHsMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win___nl",
    path: "/quiz/win",
    meta: winKDBTasO00bMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer___nl",
    path: "/quiz/wrong-answer",
    meta: wrong_45answerDvLs40TJcBMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct___nl",
    path: "/static/answer-correct",
    meta: answer_45correctdRCood4IVjMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/answer-correct.vue")
  },
  {
    name: "static-be___nl",
    path: "/static/be",
    meta: beSH9ILRETp9Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/be.vue")
  },
  {
    name: "static-congratulations___nl",
    path: "/static/congratulations",
    meta: congratulationsQaZ8iWIjxVMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/congratulations.vue")
  },
  {
    name: "static-form___nl",
    path: "/static/form",
    meta: formYVf3bj5Dj2Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/form.vue")
  },
  {
    name: "static-login___nl",
    path: "/static/login",
    meta: loginxSE5TcLQyfMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/login.vue")
  },
  {
    name: "static-question___nl",
    path: "/static/question",
    meta: question1an7fVjljlMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/question.vue")
  },
  {
    name: "static-thanks___nl",
    path: "/static/thanks",
    meta: thanksqy0TmbtEWbMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/thanks.vue")
  },
  {
    name: "static-wheel___nl",
    path: "/static/wheel",
    meta: wheelltkFT2T4pkMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241003130412/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl___nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl___nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]